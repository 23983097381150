import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import Accordion from '../AccordionItem'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'

// Animation
import AnimateImage from '../animation/AnimateImage'

// Styles
import {
  Section,
  ImageContainer,
  SingleImage,
  List,
  ListItem,
  SingleImageMask,
  SingleImageMaskWrap,
} from './index.style'

/**
 * Image Accordion Block
 *
 * Each accordion item has an image, a heading, and copy.
 * When an accordion item is selected, the image will change to match the text
 */

const ImageBlock = ({ content }) => {
  const [activeItem, setActiveItem] = useState(0)

  return (
    <Section>
      <Container>
        <Spacer size={[60, 250]} />
        <Grid>
          <GridItem tabletL={5} tabletLStart={2}>
            <ImageContainer>
              {content.imageAccordionItems.map((item, key) => (
                <SingleImage key={key} active={key === activeItem}>
                  <SingleImageMaskWrap
                    active={activeItem}
                    num={key}
                    data-active={key === activeItem ? true : false}
                    data-num={key}
                  >
                    <SingleImageMask active={activeItem} num={key}>
                      {key === 0 ? (
                        <AnimateImage delay={0.6}>
                          <GatsbyImage
                            image={item.image.gatsbyImageData}
                            alt={item.image.title}
                          />
                        </AnimateImage>
                      ) : (
                        <GatsbyImage
                          image={item.image.gatsbyImageData}
                          alt={item.image.title}
                        />
                      )}
                    </SingleImageMask>
                  </SingleImageMaskWrap>
                </SingleImage>
              ))}
            </ImageContainer>
          </GridItem>
          <GridItem tabletL={4} tabletLStart={8} tabletLAlign={`center`}>
            <List>
              {content.imageAccordionItems.map((item, key) => (
                <ListItem
                  key={key}
                  active={key === activeItem ? true : false}
                  onClick={() => {
                    if (activeItem !== key) {
                      setActiveItem(key)
                    } else {
                      setActiveItem(-1)
                    }
                  }}
                >
                  {key !== 0 && <Spacer size={[28, 40]} />}
                  <Accordion
                    content={item}
                    delay={0.2 * key}
                    active={key === activeItem ? true : false}
                  />
                  <Spacer size={[25, 30]} />
                </ListItem>
              ))}
            </List>
          </GridItem>
        </Grid>
      </Container>
    </Section>
  )
}

export default ImageBlock
