import styled from 'styled-components'
import {
  DialogOverlay as ReachOverlay,
  DialogContent as ReachContent,
} from '@reach/dialog'
import { transparentize } from 'polished'
import { colors } from '../../styles/vars/colors.style'

export const ModalWrapper = styled(ReachOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  z-index: 99;
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${transparentize(0.1, colors.dark)};
  opacity: 0;
`

export const ModalContent = styled(ReachContent)`
  position: relative;
  width: auto;
  outline: none;
  opacity: 0;
  padding: 2.4rem;
`
