import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const TextMediaBlockWrapper = styled.div`
  color: ${colors.cream};
  overflow: hidden;

  ${mq.tabletLMax} {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
`

export const TextMediaBlockWrapperInner = styled.div`
  background-color: ${colors.green};
`
export const TextMediaBlockColumn = styled.div`
  ${clamp('padding-top', 40, 157)}
  ${clamp('padding-bottom', 40, 157)}

  ${mq.tabletLMax} {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  ${mq.tabletL} {
    ${props =>
      props.textLeft
        ? clamp('padding-right', 50, 66, breakpoints.tabletL)
        : `${clamp('padding-left', 50, 110, breakpoints.tabletL)}${clamp(
            'padding-right',
            50,
            110,
            breakpoints.tabletL
          )}`}
  }
`

export const TextMediaBlockVideo = styled.div`
  overflow: hidden;
  position: relative;
  height: 80vw;

  ${mq.tabletL} {
    height: 100%;
  }
`

export const VimeoPlayerMain = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${props =>
      props.aspectRatio ? `${props.aspectRatio * 100}%` : '56.25%'};
  }

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => (props.playing ? 1 : 0)};
    visibility: ${props => (props.playing ? 'visible' : 'hidden')};
    transition: opacity 0.5s ${easings.inOut.default}, visibility 0s 0.5s;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
