import styled from 'styled-components'
import { flow } from '../../styles/utils/functions.style'
import { easings } from '../../styles/vars/easings.style'
import {
  getStyles,
  TextBody,
  textStylesConfig,
} from '../../styles/vars/textStyles.style'

export const RichTextMain = styled.div`
  ${flow(2)}

  strong,
  em,
  u,
  a {
    display: inline;
  }

  a {
    text-decoration: underline;
  }
`

export const UnorderedList = styled.ul`
  list-style: none;
`

export const OrderedList = styled.ol`
  list-style: none;
`

export const ListItem = styled.li`
  ${TextBody} {
    position: relative;
    padding-left: 0.75em;
    ${getStyles(textStylesConfig.bodySmall)}

    :before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      transform: ${props =>
        props.inView ? 'translateY(0)' : 'translateY(300%)'};
      display: block;
      width: 0.3rem;
      height: 0.3rem;
      overflow: hidden;
      opacity: ${props => (props.inView ? 1 : 0)};
      border-radius: 50%;
      background-color: currentColor;
      transition: transform 0.3s ${easings.inOut.default},
        opacity 0.3s ${easings.inOut.default};
      transition-delay: ${props => `${props.delay + 0.05}s`};
    }
  }

  & + & {
    margin-top: 0.5em;
  }
`
