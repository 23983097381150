import React, { useState, useRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSwipeable } from 'react-swipeable'
import { updateHeader, useStore } from '../../Store'

// Components
import AnimatedArrow from '../AnimatedArrow'
import AnimateSplitText from '../animation/AnimateSplitText'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import ImageGalleryItem from '../ImageGalleryItem'
import Spacer from '../Spacer'

// Styles
import {
  Section,
  GalleryText,
  GalleryNav,
  GalleryButton,
  Gallery,
  GalleryInner,
} from './index.style'
import { Heading2, Label } from '../TextStyles'
import { animation } from '../../styles/vars/animation.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'

/**
 * Image gallery block
 *
 * A carousel of images with closed accordions that open when clicked
 * On desktop, each 'image' also contains a Mini Gallery
 */

const ImageGalleryBlock = ({ content }) => {
  const [, dispatch] = useStore()
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: animation.blockThreshold,
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (window.innerWidth < breakpoints.tabletL) {
        if (activeItem < maxItems) {
          setActiveItem(activeItem + 1)
        }
      }
    },
    onSwipedRight: () => {
      if (window.innerWidth < breakpoints.tabletL) {
        if (activeItem > 0) {
          setActiveItem(activeItem - 1)
        }
      }
    },
  })

  const [activeItem, setActiveItem] = useState(0)
  const [maxItems, setMaxItems] = useState(content.carouselItems.length - 1)

  const galleryRef = useRef(null)

  useEffect(() => {
    const { current } = galleryRef,
      singleItem = current.querySelectorAll('div')[0]

    const update = () => {
      if (window.innerWidth < 1024) {
        setMaxItems(content.carouselItems.length - 1)
      } else {
        setMaxItems(content.carouselItems.length - 2)
      }

      const width = singleItem.offsetWidth
      const moveX = activeItem * -width
      current.style.transform = `translateX(${moveX}px)`
    }

    update()

    window.addEventListener('resize', update)

    return () => {
      window.removeEventListener('resize', update)
    }
  }, [activeItem, content.carouselItems.length])

  useEffect(() => {
    if (content.anchorIdOptional) {
      if (inView === true) {
        updateHeader(dispatch, content.anchorIdOptional)
      }
    }
  }, [inView, content.anchorIdOptional, dispatch])

  return (
    <Section ref={ref}>
      <Spacer size={[60, 250]} />
      <GalleryText>
        <Container>
          <div id={content.anchorIdOptional && content.anchorIdOptional}></div>
          <Spacer size={[60, 250]} />
          <Grid>
            <GridItem
              tiny={10}
              tabletL={5}
              tabletLStart={2}
              deskL={4}
              deskLStart={2}
            >
              <AnimateSplitText>
                <Label as={`h3`}>{content.subheading}</Label>
              </AnimateSplitText>
              <Spacer size={[25, 40]} />
              <AnimateSplitText delay={0.2}>
                <Heading2 as={`h2`}>{content.heading}</Heading2>
              </AnimateSplitText>
            </GridItem>
            <GridItem
              tiny={6}
              tinyStart={7}
              mobile={5}
              mobileStart={8}
              tabletL={4}
              tabletLStart={9}
              tabletLAlign={`self-end`}
            >
              <GalleryNav>
                <GalleryButton
                  isDisabled={activeItem > 0 ? false : true}
                  prev
                  onClick={() => {
                    if (activeItem > 0) {
                      setActiveItem(activeItem - 1)
                    }
                  }}
                  aria-label={`Previous item`}
                >
                  <AnimatedArrow prev />
                </GalleryButton>
                <GalleryButton
                  isDisabled={activeItem < maxItems ? false : true}
                  onClick={() => {
                    if (activeItem < maxItems) {
                      setActiveItem(activeItem + 1)
                    }
                  }}
                  aria-label={`Next item`}
                >
                  <AnimatedArrow />
                </GalleryButton>
                <Spacer size={[30, 100]} />
              </GalleryNav>
            </GridItem>
          </Grid>
        </Container>
      </GalleryText>

      <Gallery {...handlers}>
        <Container>
          <Grid>
            <GridItem tabletL={11} tabletLStart={2}>
              <GalleryInner
                ref={galleryRef}
                activeItem={activeItem}
                maxItems={maxItems}
              >
                {content.carouselItems.map((item, key) => {
                  return <ImageGalleryItem item={item} key={key} num={key} />
                })}
              </GalleryInner>
            </GridItem>
          </Grid>
        </Container>
      </Gallery>
    </Section>
  )
}

export default ImageGalleryBlock
