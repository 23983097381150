import React from 'react'
import { graphql } from 'gatsby'

// Components
import AcquisitionBlock from '../components/AcquisitionBlock'
import HomeHero from '../components/HomeHero'
import ImageAccordionBlock from '../components/ImageAccordionBlock'
import ImageBlock from '../components/ImageBlock'
import ImageGalleryBlock from '../components/ImageGalleryBlock'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import TextBlock from '../components/TextBlock'
import TextMediaBlock from '../components/TextMediaBlock'
import TeamBlock from '../components/TeamBlock'

const IndexPage = ({ data }) => {
  const { seoTitle, seoDescription, slug, heroTitle, heroCopy, pageContent } =
    data.contentfulHomePage

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <Spacer size={[182, 400]} />

      <HomeHero heroTitle={heroTitle} heroCopy={heroCopy} />

      <Spacer size={40} />

      {React.Children.toArray(
        pageContent.map((block, key) => {
          if (block?.internal?.type === 'ContentfulBlockText') {
            return <TextBlock content={block} />
          } else if (block?.internal?.type === 'ContentfulBlockTextAndMedia') {
            return <TextMediaBlock content={block} num={key} />
          } else if (
            block?.internal?.type === 'ContentfulBlockFullWidthImage'
          ) {
            return <ImageBlock content={block} />
          } else if (
            block?.internal?.type === 'ContentfulBlockImageAccordion'
          ) {
            return <ImageAccordionBlock content={block} />
          } else if (block?.internal?.type === 'ContentfulBlockAcquisition') {
            return <AcquisitionBlock content={block} />
          } else if (block?.internal?.type === 'ContentfulBlockTeam') {
            return <TeamBlock content={block} />
          } else if (block?.internal?.type === 'ContentfulBlockCarousel') {
            return <ImageGalleryBlock content={block} />
          } else return null
        })
      )}
    </>
  )
}

export default IndexPage

export const homePageQuery = graphql`
  query HomePage {
    contentfulHomePage(slug: { eq: "/" }) {
      seoTitle
      seoDescription
      seoImage {
        gatsbyImageData
      }
      slug
      heroTitle
      heroCopy {
        raw
      }
      pageContent {
        ... on ContentfulBlockFullWidthImage {
          contentful_id
          internal {
            type
          }
          image {
            gatsbyImageData(width: 3032, height: 1706)
            title
          }
        }
        ... on ContentfulBlockCarousel {
          contentful_id
          internal {
            type
          }
          anchorIdOptional
          subheading
          heading
          carouselItems {
            name
            richCopy {
              raw
            }
            mainImage {
              title
              gatsbyImageData(width: 1320, height: 660)
            }
            images {
              title
              gatsbyImageData(width: 1320, height: 660)
            }
          }
        }
        ... on ContentfulBlockImageAccordion {
          contentful_id
          internal {
            type
          }
          imageAccordionItems {
            title
            copy
            copyRichText {
              raw
            }
            image {
              gatsbyImageData(width: 1492, height: 1492)
              title
            }
          }
        }
        ... on ContentfulBlockText {
          contentfulid
          contentful_id
          layout
          subheading
          heading
          copy {
            raw
          }
          internal {
            type
          }
        }
        ... on ContentfulBlockTextAndMedia {
          internal {
            type
          }
          title
          largeText {
            largeText
          }
          text {
            text
          }
          image {
            gatsbyImageData(width: 1492, height: 1520)
            title
          }
          anchorIdOptional
          videoId
          blockLayout: layout
        }
        ... on ContentfulBlockAcquisition {
          internal {
            type
          }
          anchorIdOptional
          title
          description {
            description
          }
          classification {
            classification
          }
          exclusions {
            exclusions
          }
          location {
            location
          }
          transactionSize {
            transactionSize
          }
          address {
            address
          }
          contact {
            contact
          }
        }
        ... on ContentfulBlockTeam {
          internal {
            type
          }
          label
          title
          teams {
            title
            members {
              name
              role
              bio {
                bio
              }
              bioRichText {
                raw
              }
            }
          }
        }
      }
    }
  }
`
