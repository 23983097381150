import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { FadeInWrapper } from './index.style'

const AnimateFadeIn = ({ delay = 0, children }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <FadeInWrapper ref={ref} inView={inView} delay={delay}>
      {children}
    </FadeInWrapper>
  )
}

AnimateFadeIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
}

export default AnimateFadeIn
