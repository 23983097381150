import React from 'react'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'

// Components
import AnimateSplitText from '../animation/AnimateSplitText'
import Spacer from '../Spacer'
import RichTextSlide from '../RichTextSlide'

// Styles
import { TeamMemberMain } from './index.style'
import { TextBody, TextBodySmallest } from '../TextStyles'
import { colors } from '../../styles/vars/colors.style'

const TeamMember = ({ content, index }) => {
  const { name, role, bioRichText } = content

  return (
    <TeamMemberMain>
      <TextBody as="h5">
        <AnimateSplitText delay={index * 0.1}>{name}</AnimateSplitText>
      </TextBody>

      <Spacer size={[2, 5]} />

      <TextBodySmallest as="h6" color={transparentize(0.4, colors.black)}>
        <AnimateSplitText delay={index * 0.1}>{role}</AnimateSplitText>
      </TextBodySmallest>

      <Spacer size={[10, 15]} />

      <RichTextSlide
        content={bioRichText}
        delay={index * 0.1}
        smallerParagraph
      />
    </TeamMemberMain>
  )
}

TeamMember.propTypes = {
  content: PropTypes.object,
  index: PropTypes.number,
}

export default TeamMember
