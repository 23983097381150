import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { GridItemMain } from '../GridItem/index.style'
import { SpacerMain } from '../Spacer/index.style'

export const Section = styled.div`
  overflow: hidden;
  position: relative;
`
export const GalleryText = styled.div`
  background-color: ${colors.green};
  color: ${colors.cream};
  height: 100%;
  position: relative;

  ${mq.tabletL} {
    ${clamp('padding-bottom', 51, 100)}
  }
`

export const GalleryNav = styled.div`
  position: relative;

  ${mq.tabletL} {
    ${SpacerMain} {
      display: none;
    }
  }
`

export const GalleryButton = styled.button`
  background-color: transparent;
  border: 0.1rem solid ${colors.cream};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  ${clamp('height', 51, 70)}
  ${clamp('width', 51, 70)}
  transition: opacity .5s ${easings.inOut.default};

  ${({ isDisabled }) =>
    isDisabled === true
      ? `pointer-events: none; opacity: .4;`
      : `pointer-events:all; opacity:1;`}

  ${({ prev }) =>
    prev &&
    `
  ${clamp('margin-right', 10, 14)}`}
`

export const Gallery = styled.div`
  overflow: hidden;
  position: relative;
  top: -0.1rem;

  ${GridItemMain} {
    height: 100%;
  }

  &:before {
    background-color: ${colors.green};
    top: -0.4rem;
    left: -2.4rem;
    position: absolute;
    right: -2.4rem;
    content: '';
    ${clamp('height', 100, 195)}
  }
`

export const GalleryInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ${easings.inOut.default};
  width: calc(100% * ${({ maxItems }) => maxItems + 1});
`
