import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import AnimateSplitText from '../animation/AnimateSplitText'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import Team from '../Team'

// Styles
import { Heading2, Label } from '../TextStyles'
import { colors } from '../../styles/vars/colors.style'

// Functions
import { scrollToId } from '../../utils/utils'

/**
 * Team Block
 */

const TeamBlock = ({ content }) => {
  const [prev, setPrev] = useState(-1)
  const [active, setActive] = useState(-1)
  const { label, title, teams } = content

  useEffect(() => {
    console.log('updated')
    if (active !== prev && prev > -1 && active > -1) {
      console.log('scrolled')
      scrollToId('team-carousel')
    }
    setPrev(active)
  }, [prev, active])

  return (
    <>
      <Spacer size={[60, 250]} />
      <div id="team-carousel" />
      <Container>
        <Grid>
          <GridItem tabletP={8} tabletPStart={3}>
            <Grid>
              <GridItem tabletP={5} desk={4} deskL={3}>
                <Label>
                  <AnimateSplitText>{label}</AnimateSplitText>
                </Label>

                <Spacer size={[25, 40]} />

                <Heading2 as="h3" color={colors.green}>
                  <AnimateSplitText>{title}</AnimateSplitText>
                </Heading2>
              </GridItem>
            </Grid>

            <Spacer size={[30, 50]} />

            {React.Children.toArray(
              teams.map((team, teamIndex) => (
                <Team
                  data={team}
                  setActive={setActive}
                  teamIndex={teamIndex}
                  open={active === teamIndex}
                />
              ))
            )}
          </GridItem>
        </Grid>
      </Container>

      <Spacer size={[60, 250]} />
    </>
  )
}

TeamBlock.propTypes = {
  content: PropTypes.object,
}

export default TeamBlock
