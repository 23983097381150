import React, { useEffect, useRef } from 'react'

// Components
import Spacer from '../Spacer'

// Animation
import AnimateSlideIn from '../animation/AnimateSlideIn'

// Styles
import { AccordionItem, AccordionInnerContent } from './index.style'
import { TextBody } from '../TextStyles'
import RichText from '../RichText'

const Accordion = ({ content, active, delay }) => {
  const contentRef = useRef(null)

  useEffect(() => {
    const { current } = contentRef

    const updateHeight = () => {
      if (active === true) {
        current.style.height = current.scrollHeight + `px`
        current.style.maxHeight = current.scrollHeight + `px`
      } else {
        current.style.maxHeight = 0 + `px`
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)
  }, [active])

  return (
    <AnimateSlideIn delay={delay}>
      <AccordionItem active={active}>
        <button aria-label="Toggle Item">
          <TextBody as={`h3`}>{content.title}</TextBody>
        </button>
        <AccordionInnerContent ref={contentRef}>
          <Spacer size={[10, 15]} />
          <RichText content={content.copyRichText} smallerParagraph />
        </AccordionInnerContent>
      </AccordionItem>
    </AnimateSlideIn>
  )
}

export default Accordion
