import styled from 'styled-components'
import { TextBody } from '../../styles/vars/textStyles.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const AccordionItem = styled.div`
  cursor: pointer;
  position: relative;

  > button {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    color: currentColor;
    cursor: pointer;
  }

  ${TextBody} {
    color: ${({ active }) => active === true && colors.green};
  }

  ${mq.desk} {
    &:hover {
      ${TextBody} {
        color: ${colors.green};
      }
    }
  }
`
export const AccordionInnerContent = styled.div`
  max-height: 0rem;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ${easings.inOut.default};
`
