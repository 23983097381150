import styled from 'styled-components'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'

export const Section = styled.div`
  position: relative;
`
export const ImageContainer = styled.div`
  /* ${clamp('margin-left', -24, -48, breakpoints.mobile, breakpoints.tabletL)}
  ${clamp('margin-right', -24, -48, breakpoints.mobile, breakpoints.tabletL)} */
  position: relative;

  ${mq.tabletL} {
    margin-left: 0;
    margin-right: 0;
  }
`

export const SingleImage = styled.div`
  overflow: hidden;
  pointer-events: ${({ active }) => (active ? `all` : `none`)};

  &:not(:first-of-type) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const SingleImageMaskWrap = styled.div`
  overflow: hidden;
  transition: transform 0.5s ${easings.inOut.default};
  ${({ num, active }) =>
    active === num
      ? `transform: translateX(0);`
      : active > num
      ? `transform: translateX(-101%); transition-delay: .1s; `
      : `transform: translateX(101%);  transition-delay: .1s;`}

  ${({ num, active }) =>
    active === -1 && num === 0 ? `transform: translateX(0);` : ``}
`

export const SingleImageMask = styled.div`
  transition: transform 0.5s ${easings.inOut.default};
  ${({ num, active }) =>
    active === num
      ? `transform: translateX(0);`
      : active > num
      ? `transform: translateX(101%); transition-delay: .1s; `
      : `transform: translateX(-101%); transition-delay: .1s; `}

  ${({ num, active }) =>
    active === -1 && num === 0 ? `transform: translateX(0);` : ``}
`

export const List = styled.ul`
  list-style-type: none;
  margin-top: 4rem;

  ${mq.tabletL} {
    margin-top: 0;
  }
`
export const ListItem = styled.li`
  cursor: ${({ active }) => (active === true ? `unset` : `pointer`)};
  position: relative;

  &:not(:last-of-type) {
    &:after {
      bottom: 0;
      background-color: ${colors.lightGreen};
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
    }
  }
`
