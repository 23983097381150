import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Animation
import AnimateImage from '../animation/AnimateImage'

// Styles
import {
  Section,
  GalleryInner,
  GalleryImageMask,
  GalleryImages,
  GalleryImage,
} from './index.style'
import { colors } from '../../styles/vars/colors.style'

/**
 * Mini Gallery
 *
 * Is only shown from destop size
 */

const MiniGallery = ({ images, clickEvent, num }) => (
  <Section>
    <GalleryInner>
      <GalleryImages>
        <GalleryImage num={0} onClick={clickEvent}>
          <GalleryImageMask num={0}>
            <AnimateImage
              color={colors.lightGreen}
              delay={num && 0.1 * (num + 1)}
            >
              <GatsbyImage
                image={images.mainImage.gatsbyImageData}
                loading={`eager`}
                alt={images.mainImage.title}
              />
            </AnimateImage>
          </GalleryImageMask>
        </GalleryImage>
      </GalleryImages>
    </GalleryInner>
  </Section>
)

export default MiniGallery
