import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'

// Animation
import AnimateImage from '../animation/AnimateImage'

// Styles
import { Section, ImageContainer } from './index.style'

/**
 * Image Block
 *
 * Shows a landscape image
 */

const ImageBlock = ({ content }) => {
  return (
    <Section>
      <Container>
        <Spacer size={[40, 100]} />
        <Grid>
          <GridItem tabletL={10} tabletLStart={2}>
            <ImageContainer>
              <AnimateImage>
                <GatsbyImage
                  image={content.image.gatsbyImageData}
                  alt={content.image.title}
                />
              </AnimateImage>
            </ImageContainer>
          </GridItem>
        </Grid>{' '}
      </Container>
    </Section>
  )
}

export default ImageBlock
