import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

import { updateHeader, useStore } from '../../Store'

//Components
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import AnimateSplitText from '../animation/AnimateSplitText'

// Styles
import {
  AcquisitionBlockBorder,
  AcquisitionBlockMain,
  AcquisitionBlockText,
  AcquisitionBlockDescription,
} from './index.style'
import { Heading2, TextBodySmaller, TextBodySmallest } from '../TextStyles'

import { animation } from '../../styles/vars/animation.style'

// Functions
import { formatMarkdown } from '../../utils/utils'

/**
 * Acuisition Block
 *
 * Dark backround
 * Contains only text
 */

const AcquisitionBlock = ({ content }) => {
  const [, dispatch] = useStore()
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: animation.blockThreshold,
  })

  const {
    title,
    description,
    classification,
    exclusions,
    location,
    transactionSize,
    address,
    contact,
    anchorIdOptional,
  } = content

  useEffect(() => {
    if (anchorIdOptional) {
      if (inView === true) {
        updateHeader(dispatch, anchorIdOptional)
      }
    }
  }, [inView, anchorIdOptional, dispatch])

  return (
    <div>
      <Spacer size={[60, 250]} />
      <AcquisitionBlockBorder
        ref={ref}
        id={anchorIdOptional && anchorIdOptional}
      />
      <AcquisitionBlockMain>
        <Container>
          <Grid>
            <GridItem tabletL={3} tabletLStart={2}>
              <Heading2 as="h3">
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading2>

              <Spacer size={[20, 35]} />

              {description && (
                <AcquisitionBlockDescription>
                  <TextBodySmaller>
                    <AnimateSplitText>
                      {description.description}
                    </AnimateSplitText>
                  </TextBodySmaller>
                </AcquisitionBlockDescription>
              )}
            </GridItem>

            <GridItem tabletL={6} tabletLStart={6}>
              <Grid>
                {classification && (
                  <GridItem tabletL={3}>
                    <AcquisitionBlockText>
                      <TextBodySmallest>
                        <AnimateSplitText>
                          {formatMarkdown(classification.classification)}
                        </AnimateSplitText>
                      </TextBodySmallest>
                    </AcquisitionBlockText>
                  </GridItem>
                )}

                {exclusions && (
                  <GridItem tabletL={3}>
                    <AcquisitionBlockText>
                      <TextBodySmallest>
                        <AnimateSplitText>
                          {formatMarkdown(exclusions.exclusions)}
                        </AnimateSplitText>
                      </TextBodySmallest>
                    </AcquisitionBlockText>
                  </GridItem>
                )}

                {(location || transactionSize) && (
                  <GridItem tabletL={3}>
                    <AcquisitionBlockText>
                      {location && (
                        <TextBodySmallest>
                          <AnimateSplitText>
                            {formatMarkdown(location.location)}
                          </AnimateSplitText>
                        </TextBodySmallest>
                      )}
                      {transactionSize && (
                        <TextBodySmallest>
                          <AnimateSplitText>
                            {formatMarkdown(transactionSize.transactionSize)}
                          </AnimateSplitText>
                        </TextBodySmallest>
                      )}
                    </AcquisitionBlockText>
                  </GridItem>
                )}

                {address && (
                  <GridItem tabletL={3}>
                    <AcquisitionBlockText>
                      <TextBodySmallest>
                        <AnimateSplitText>
                          {formatMarkdown(address.address)}
                        </AnimateSplitText>
                      </TextBodySmallest>
                    </AcquisitionBlockText>
                  </GridItem>
                )}

                {contact && (
                  <GridItem tabletL={3}>
                    <AcquisitionBlockText>
                      <TextBodySmallest>
                        <AnimateSplitText>
                          {formatMarkdown(contact.contact)}
                        </AnimateSplitText>
                      </TextBodySmallest>
                    </AcquisitionBlockText>
                  </GridItem>
                )}
              </Grid>
            </GridItem>
          </Grid>
        </Container>
      </AcquisitionBlockMain>
    </div>
  )
}

AcquisitionBlock.propTypes = {
  content: PropTypes.object,
}

export default AcquisitionBlock
