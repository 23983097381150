import React, { useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

// Components
import AnimateSplitText from '../animation/AnimateSplitText'
import AnimatedArrow from '../AnimatedArrow'
import Spacer from '../Spacer'
import Grid from '../Grid'
import GridItem from '../GridItem'
import TeamMember from '../TeamMember'

// Styles
import { TeamContent, TeamMain, TeamToggleIcon } from './index.style'
import { TextBody } from '../TextStyles'
import { colors } from '../../styles/vars/colors.style'

/**
 * Individual Team
 *
 * Opens and closes like an accordion
 * Uses GSAP to animate max height
 */

const Team = ({ data, setActive, teamIndex, open }) => {
  const { title, members } = data
  const contentRef = useRef()

  useLayoutEffect(() => {
    const contentEl = contentRef.current

    if (open) {
      gsap.fromTo(
        contentEl,
        {
          maxHeight: 0,
        },
        {
          maxHeight: contentEl.scrollHeight,
          ease: 'power3.inOut',
          duration: 0.4,
        }
      )

      gsap.to(contentEl, {
        opacity: 1,
        duration: 0.2,
        ease: 'power3.inOut',
        delay: 0.3,
      })
    } else {
      gsap.fromTo(
        contentEl,
        {
          maxHeight: contentEl.scrollHeight,
        },
        {
          maxHeight: 0,
          ease: 'power3.inOut',
          duration: 0.4,
        }
      )

      gsap.to(contentEl, {
        opacity: 0,
        duration: 0.2,
        ease: 'power3.inOut',
      })
    }
  }, [open])

  useEffect(() => {
    const contentEl = contentRef.current

    const updateMaxHeight = () => {
      if (parseInt(contentEl.style.maxHeight, 10) > 0) {
        contentEl.style.maxHeight = `${contentEl.scrollHeight}px`
      }
    }

    window.addEventListener('resize', updateMaxHeight)

    return () => {
      window.removeEventListener('resize', updateMaxHeight)
    }
  }, [])

  const toggleItem = () => {
    setActive(open ? -1 : teamIndex)
  }

  return (
    <TeamMain onClick={toggleItem} open={open}>
      <TeamToggleIcon open={open} aria-label={`Toggle Open/Closed`}>
        <AnimatedArrow color={colors.black} big />
      </TeamToggleIcon>

      <Spacer size={[25, 50]} />

      <Grid tabletP={8}>
        <GridItem tabletP={3}>
          <TextBody as="h4">
            <AnimateSplitText>{title}</AnimateSplitText>
          </TextBody>
        </GridItem>

        <GridItem tabletP={4}>
          <TeamContent ref={contentRef}>
            {React.Children.toArray(
              members.map((teamMember, teamMemberIndex) => (
                <TeamMember content={teamMember} index={teamMemberIndex} />
              ))
            )}
          </TeamContent>
        </GridItem>
      </Grid>

      <Spacer size={[25, 50]} />
    </TeamMain>
  )
}

Team.propTypes = {
  data: PropTypes.object,
  setActive: PropTypes.func,
  teamIndex: PropTypes.number,
  open: PropTypes.bool,
}

export default Team
