import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/vimeo'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

import { updateHeader, useStore } from '../../Store'

// Components
import AnimateImage from '../animation/AnimateImage'
import AnimateSplitText from '../animation/AnimateSplitText'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
// import VimeoPlayer from '../VimeoPlayer'

// Styles
import {
  TextMediaBlockColumn,
  TextMediaBlockVideo,
  TextMediaBlockWrapper,
  TextMediaBlockWrapperInner,
  VimeoPlayerMain,
} from './index.style'
import { Label, TextBody, TextBodySmaller } from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'

/**
 * Text Media Block
 *
 * Works with either an image or a video
 * Has a green background and is centered on the page
 */

const TextMediaBlock = ({ content, num }) => {
  const {
    blockLayout,
    title,
    largeText: { largeText },
    text: { text },
    image,
    videoId,
    anchorIdOptional,
  } = content

  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [videoDimensions, setVideoDimensions] = useState(null)
  const [coverWidth, setCoverWidth] = useState(null)

  const wrapperRef = useRef(null)
  const videoRef = useRef(null)

  const [, dispatch] = useStore()
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })

  useEffect(() => {
    if (anchorIdOptional) {
      if (inView === true) {
        updateHeader(dispatch, anchorIdOptional)
      }
    }
  }, [inView, anchorIdOptional, dispatch])

  useEffect(() => {
    if (!videoRef.current) return

    let videoDimensionsLocal

    const updateCoverDimensions = () => {
      const targetDimensions = {
        width: wrapperRef.current.offsetWidth,
        height: wrapperRef.current.offsetHeight,
      }
      if (videoDimensionsLocal) {
        const subPixelOffset = 2
        if (
          targetDimensions.width *
            (videoDimensionsLocal[1] / videoDimensionsLocal[0]) <
          targetDimensions.height + subPixelOffset
        ) {
          setCoverWidth(
            (targetDimensions.height + subPixelOffset) *
              (videoDimensionsLocal[0] / videoDimensionsLocal[1])
          )
        }
      }
    }

    Promise.all([
      videoRef.current.getInternalPlayer()?.getVideoWidth(),
      videoRef.current.getInternalPlayer()?.getVideoHeight(),
    ]).then(dimensions => {
      setVideoDimensions(dimensions)
      videoDimensionsLocal = dimensions
      updateCoverDimensions()
    })

    window.addEventListener('resize', updateCoverDimensions)

    return () => {
      window.removeEventListener('resize', updateCoverDimensions)
    }
  }, [videoIsPlaying])

  const TextContent = useCallback(
    () => (
      <>
        <AnimateSlideIn delay={num === 0 ? 2 : 0}>
          <Label as="h2">{title}</Label>
        </AnimateSlideIn>

        <Spacer size={[25, 40]} />

        <TextBody>
          <AnimateSplitText delay={num === 0 ? 2.2 : 0.2}>
            {largeText}
          </AnimateSplitText>
        </TextBody>

        <Spacer size={[20, 30]} />

        <TextBodySmaller>
          <AnimateSplitText delay={num === 0 ? 2.4 : 0.4}>
            {text}
          </AnimateSplitText>
        </TextBodySmaller>
      </>
    ),
    [title, largeText, text, num]
  )

  const MediaContent = useCallback(
    () => (
      <>
        {image && (
          <AnimateImage delay={num === 0 ? 2 : 0}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
          </AnimateImage>
        )}
        {videoId && (
          <TextMediaBlockVideo ref={wrapperRef}>
            <VimeoPlayerMain
              aspectRatio={
                videoDimensions && videoDimensions[1] / videoDimensions[0]
              }
              style={{ width: coverWidth }}
              playing={videoIsPlaying}
            >
              <ReactPlayer
                ref={videoRef}
                playing={videoIsPlaying}
                style={{
                  pointerEvents: 'none',
                }}
                loop={true}
                muted={true}
                playsinline={true}
                width={'100%'}
                height={'100%'}
                url={`https://vimeo.com/${videoId}`}
                onReady={() => setVideoIsPlaying(true)}
              />
            </VimeoPlayerMain>
          </TextMediaBlockVideo>
        )}
      </>
    ),
    [image, videoId, num, videoIsPlaying, coverWidth, videoDimensions]
  )

  return (
    <Container>
      <Spacer size={[60, 220]} />

      <Grid>
        <GridItem desk={10} deskStart={2}>
          <TextMediaBlockWrapper
            id={anchorIdOptional && anchorIdOptional}
            ref={ref}
          >
            <TextMediaBlockWrapperInner num={num}>
              <Grid desk={10} align>
                {blockLayout ? (
                  <>
                    <GridItem
                      tabletLAlign="center"
                      tabletL={5}
                      tabletLStart={2}
                      desk={4}
                      deskStart={2}
                    >
                      <TextMediaBlockColumn textLeft={blockLayout}>
                        <TextContent />
                      </TextMediaBlockColumn>
                    </GridItem>

                    <GridItem tabletL={6} desk={5}>
                      <MediaContent />
                    </GridItem>
                  </>
                ) : (
                  <>
                    <GridItem tabletL={6} desk={5}>
                      <MediaContent />
                    </GridItem>

                    <GridItem tabletLAlign="center" tabletL={6} desk={5}>
                      <TextMediaBlockColumn textLeft={blockLayout}>
                        <TextContent />
                      </TextMediaBlockColumn>
                    </GridItem>
                  </>
                )}
              </Grid>
            </TextMediaBlockWrapperInner>
          </TextMediaBlockWrapper>
        </GridItem>
      </Grid>
    </Container>
  )
}

TextMediaBlock.propTypes = {
  content: PropTypes.object.isRequired,
}

export default TextMediaBlock
