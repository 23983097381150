import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const TeamMain = styled.div`
  position: relative;
  border-top: 0.1rem solid ${colors.lightGreen};
  cursor: pointer;

  :last-child {
    border-bottom: 0.1rem solid ${colors.lightGreen};
  }

  h4 {
    ${({ open }) => open === true && ` color: ${colors.green};`}
  }

  ${mq.desk} {
    &:hover {
      h4 {
        color: ${colors.green};
      }
    }
  }
`

export const TeamContent = styled.div`
  overflow: hidden;
  opacity: 0;
`

export const TeamToggleIcon = styled.button`
  position: absolute;
  ${clamp('top', 40, 68)}
  right: 0;
  transform: ${props => (props.open ? 'rotate(-90deg)' : 'rotate(90deg)')};
  ${clamp('width', 9, 15)}
  border: none;
  background: transparent;
  transition: transform 0.15s ${easings.inOut.default};
`
