import React from 'react'
import { useInView } from 'react-intersection-observer'

// Components
import AnimateSplitText from '../animation/AnimateSplitText'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import RichText from '../RichText'

// Styles
import { animation } from '../../styles/vars/animation.style'
import { colors } from '../../styles/vars/colors.style'
import { Heading1 } from '../../styles/vars/textStyles.style'
import { Hero, HeadingContainer } from './index.style'

// SVGs
import DateBadge from '../svgs/DateBadge'
import Container from '../Container'

const HomeHero = ({ heroTitle, heroCopy }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: animation.blockThreshold,
  })
  return (
    <Hero>
      <Container>
        <Grid>
          <GridItem
            mobileL={11}
            tabletL={8}
            tabletLStart={3}
            desk={6}
            deskStart={3}
          >
            <HeadingContainer ref={ref} inView={inView}>
              <AnimateSplitText delay={1.2}>
                <Heading1 as={`h1`} color={colors.green}>
                  {heroTitle}
                </Heading1>
              </AnimateSplitText>
              <DateBadge />
            </HeadingContainer>
            <Spacer size={[0, 20]} />
          </GridItem>
          <GridItem
            tiny={10}
            tabletP={10}
            tabletL={8}
            tabletLStart={3}
            desk={6}
            deskStart={3}
            deskL={5}
            deskLStart={3}
          >
            <RichText content={heroCopy} delay={1.4} smallParagraph />
          </GridItem>
        </Grid>
      </Container>
    </Hero>
  )
}

export default HomeHero
