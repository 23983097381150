import styled from 'styled-components'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const Section = styled.div`
  position: relative;
`
export const ImageContainer = styled.div`
  ${clamp('margin-left', -24, -48, breakpoints.mobile, breakpoints.tabletL)}
  ${clamp('margin-right', -24, -48, breakpoints.mobile, breakpoints.tabletL)}

  ${mq.tabletL} {
    margin-left: 0;
    margin-right: 0;
  }
`
