import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import AnimatedArrow from '../AnimatedArrow'
import MiniGallery from '../MiniGallery'
import RichText from '../RichText'
import Spacer from '../Spacer'
import Modal, { modalAnimationDurationS } from '../Modal'

// Animation
import AnimateSplitText from '../animation/AnimateSplitText'
import AnimateFadeIn from '../animation/AnimateFadeIn'

// Styles
import {
  GalleryItem,
  ItemHeading,
  SeeMore,
  ItemCopy,
  Arrow,
  ModalInner,
  ModalInnerContent,
  ModalButton,
} from './index.style'
import { Heading2, TextBody, TextBodySmaller } from '../TextStyles'
import { colors } from '../../styles/vars/colors.style'

// SVG
import Close from '../svgs/Close'

const ImageGalleryBlock = ({ item, num }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <GalleryItem>
        <MiniGallery
          images={item}
          num={num}
          clickEvent={() => setOpen(!open)}
        />
        <ItemHeading open={open} onClick={() => setOpen(!open)}>
          <Spacer size={[20, 40]} />
          <AnimateSplitText delay={num && 0.1 * (num + 1)}>
            <TextBody as={`h3`}>{item.name}</TextBody>
          </AnimateSplitText>
          <SeeMore>
            <TextBodySmaller>See More</TextBodySmaller>
            <Arrow>
              <AnimatedArrow color={colors.black} />
            </Arrow>
          </SeeMore>
          <Spacer size={[30, 60]} />
        </ItemHeading>
      </GalleryItem>
      <Modal
        isOpen={open}
        onDismiss={() => setOpen(false)}
        ariaLabel="An example modal"
      >
        <ModalInner>
          <Spacer size={[20, 60]} />

          <AnimateFadeIn delay={modalAnimationDurationS}>
            <ModalButton onClick={() => setOpen(false)}>
              <Close fill={colors.green} />
            </ModalButton>
          </AnimateFadeIn>

          <Spacer size={[9, 24]} />

          <ModalInnerContent>
            <AnimateSplitText delay={modalAnimationDurationS}>
              <Heading2 as="h2" color={colors.green}>
                {item.name}
              </Heading2>
            </AnimateSplitText>

            <Spacer size={[20, 30]} />

            <ItemCopy>
              <RichText
                content={item.richCopy}
                smallerParagraph={true}
                delay={modalAnimationDurationS + 0.1}
              />
              <Spacer size={[20, 30]} />
            </ItemCopy>

            {item.images.map((image, key) => (
              <AnimateFadeIn
                delay={modalAnimationDurationS + 0.2 + 0.1 * key}
                key={key}
              >
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.title}
                  loading={`eager`}
                />
                <Spacer size={[15, 30]} />
              </AnimateFadeIn>
            ))}

            <Spacer size={[40, 154]} />
          </ModalInnerContent>
        </ModalInner>
      </Modal>
    </>
  )
}

export default ImageGalleryBlock
