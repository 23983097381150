import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateImageMain, AnimateImageMask } from './index.style'
import { colors } from '../../../styles/vars/colors.style'

const AnimateImage = ({ children, delay = 0, color = colors.green }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <AnimateImageMask ref={ref}>
      <AnimateImageMain inView={inView} delay={delay} color={color}>
        {children}
      </AnimateImageMain>
    </AnimateImageMask>
  )
}

AnimateImage.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
}

export default AnimateImage
