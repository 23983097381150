import styled from 'styled-components'

export const AnimateImageMask = styled.div`
  height: 100%;
  overflow: hidden;
`

export const AnimateImageMain = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${props.delay}s`,
  },
}))`
  height: 100%;
  position: relative;
  transform-origin: 50% 0%;
  transform: ${props =>
    props.inView ? 'translateX(0%) scale(1)' : 'translateX(-110%) scale(1.1)'};
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);

  &:after {
    background-color: ${({ color }) => color};
    bottom: 0;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    right: 0;
    transform-origin: right center;
    transform: ${props => (props.inView ? 'scaleX(0)' : 'scaleX(1)')};
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    transition-delay: ${props => props.delay + 0.6}s;
  }
`
