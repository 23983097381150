import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { SpacerMain } from '../Spacer/index.style'

export const Section = styled.div`
  position: relative;
`

export const GalleryPagination = styled.div`
  bottom: 1.5rem;
  display: none;
  left: 0;
  right: 0;
  position: absolute;

  ${mq.tabletL} {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    transition: opacity 0.15s ${easings.inOut.default};
    z-index: 2;

    ${({ open }) =>
      open === true
        ? `opacity: 1; pointer-events:none;`
        : `opacity: 0; pointer-events:all;`}
  }
`

export const GalleryPaginationDot = styled.div`
  background: ${colors.cream};
  border-radius: 50%;
  height: 0.5rem;
  margin-right: 1.2rem;
  opacity: ${({ activeItem, num }) => (activeItem === num ? `1` : `.4`)};
  transition: opacity 0.15s ${easings.inOut.default};
  width: 0.5rem;
`

export const GalleryInner = styled.div`
  overflow: hidden;
  position: relative;
`
export const GalleryImages = styled.div`
  background-color: ${colors.darkBrown};
  overflow: hidden;
`

export const GalleryImage = styled.div`
  cursor: pointer;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 0.5s ${easings.inOut.default};
`

export const GalleryImageMask = styled.div`
  transition: transform 0.5s ${easings.inOut.default};
  transform: translateX(0);
`

export const GalleryNav = styled.div`
  bottom: 0;
  display: none;
  left: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.25s ${easings.inOut.default};
  z-index: 2;

  &:before,
  &:after {
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    ${clamp('width', 43, 63)}
    z-index:-1;
  }

  &:before {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    left: 0;
  }
  &:after {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    right: 0;
  }

  ${mq.tabletL} {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${SpacerMain} {
      display: none;
    }
  }

  ${({ open }) =>
    open === true
      ? `opacity: 1; pointer-events:none;`
      : `opacity: 0; pointer-events:all;`}
`

export const GalleryButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  height: 1rem;
  width: 1.2rem;
  transition: opacity 0.5s ${easings.inOut.default};

  ${({ isDisabled }) =>
    isDisabled === true
      ? `pointer-events: none; opacity: .4;`
      : `pointer-events:all; opacity:1;`}

  ${({ open }) => open !== true && `pointer-events:none;`}

  ${({ prev }) =>
    prev &&
    `
  ${clamp('margin-right', 10, 14)}`}
`
