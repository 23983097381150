import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { updateHeader, useStore } from '../../Store'

// Components
import AnimateSplitText from '../animation/AnimateSplitText'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import RichText from '../RichText'
import Spacer from '../Spacer'

// Styles
import { TextSection } from './index.style'
import { Heading2 } from '../../styles/vars/textStyles.style'
import { colors } from '../../styles/vars/colors.style'
import { Label } from '../TextStyles'
import { animation } from '../../styles/vars/animation.style'

/**
 * Text Block
 *
 * Has two layouts:
 * - On mobile, 'half' is left aligned, and 'full' is centered
 * - On desktop 'half' has the heading next to the copy and 'full' is centerered and the heading is above the copy
 */

const TextBlock = ({ content }) => {
  const [, dispatch] = useStore()
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: animation.blockThreshold,
  })
  const layout = content.layout === '50/50' ? `half` : `full`

  useEffect(() => {
    if (content.contentfulid) {
      if (inView === true) {
        updateHeader(dispatch, content.contentfulid)
      }
    }
  }, [inView, content.contentfulid, dispatch])

  return (
    <>
      <Spacer size={[30, 100]} />
      <TextSection
        layout={layout}
        ref={ref}
        id={content.contentfulid && content.contentfulid}
      >
        <Spacer size={[30, 150]} />
        <Container>
          <Grid>
            <GridItem
              tiny={layout === `half` ? 9 : 12}
              tabletP={layout === `half` ? 6 : 10}
              tabletPStart={layout === `half` ? 1 : 2}
              tabletL={layout === `half` ? 4 : 8}
              tabletLStart={3}
              desk={layout === `half` ? 3 : 8}
              deskStart={3}
            >
              <AnimateSplitText>
                <Label>{content.subheading}</Label>
              </AnimateSplitText>
              <Spacer size={[25, 40]} />
              <AnimateSplitText delay={0.2}>
                {' '}
                <Heading2 as={`h2`} color={colors.green}>
                  {content.heading}
                </Heading2>
              </AnimateSplitText>
            </GridItem>
            <GridItem
              tabletP={10}
              tabletPStart={layout === `half` ? 1 : 2}
              tabletL={layout === `half` ? 5 : 8}
              tabletLStart={layout === `half` ? 7 : 3}
              tabletLAlign={layout === `half` ? `self-end` : ``}
              desk={layout === `half` ? 4 : 6}
              deskStart={layout === `half` ? 7 : 4}
              deskLAlign={layout === `half` ? `self-end` : ``}
            >
              <RichText content={content.copy} smallerParagraph delay={0.4} />
            </GridItem>
          </Grid>
        </Container>
      </TextSection>
    </>
  )
}

export default TextBlock
