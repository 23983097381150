import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const GalleryItem = styled.div`
  display: inline-block;
  height: 100%;
  position: relative;
  top: 0;
  ${clamp('padding-right', 15, 30)}
  ${clamp('width', 290, 660)}

  &:after {
    background: ${colors.lightGreen};
    bottom: 0;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    ${clamp('right', 15, 30)}
  }
`
export const ItemHeading = styled.div`
  ${({ open }) => open === true && `color: ${colors.green};`}
  cursor: pointer;
`

export const Arrow = styled.span`
  display: inline-block;
  height: 1rem;
  position: relative;
  width: 1.2rem;
`
export const SeeMore = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;

  ${Arrow} {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
`

export const ModalInner = styled.div`
  height: 90vh;
  overflow-y: scroll;
  width: 100%;
  ${clamp('max-width', 370, 1208)};
  ${clamp('padding-left', 20, 60)};
  ${clamp('padding-right', 20, 60)};
  background-color: ${colors.light};
`

export const ModalInnerContent = styled.div`
  ${clamp('padding-left', 10, 95)};
  ${clamp('padding-right', 10, 95)};

  p {
    ${clamp('margin-bottom', 20, 30)};
  }
`

export const ModalButton = styled.button`
  background: transparent;
  border: 0.1rem solid ${colors.lightGreen};
  border-radius: 50%;
  cursor: pointer;
  display: block;
  ${clamp('height', 51, 80)}
  ${clamp('width', 51, 80)}
  margin-left: auto;
  margin-right: 0;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  ${mq.tabletL} {
    svg {
      transition: transform 0.5s ${easings.inOut.default};
    }

    &:hover {
      svg {
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
      }
    }
  }
`

export const ItemCopy = styled.div`
  a {
    color: ${colors.green};
    text-decoration: none;
    transition: color 0.5s ${easings.inOut.default};

    &:hover {
      color: ${colors.darkGreen};
    }
  }
`
