import React from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading1,
  Heading2,
  Heading3,
  TextBody,
  TextBodySmall,
  TextBodySmaller,
} from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import {
  ListItem,
  OrderedList,
  RichTextMain,
  UnorderedList,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import PageTransitionLink from '../PageTransitionLink'
import InViewSection from '../InViewSection'

const RichTextSlide = ({
  content,
  delay,
  smallParagraph,
  smallerParagraph,
}) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>,
    },
    renderNode: {
      /*
       ** Blocks
       */

      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (smallerParagraph) {
          return (
            <TextBodySmaller>
              <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
            </TextBodySmaller>
          )
        } else if (smallParagraph) {
          return (
            <TextBodySmall>
              <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
            </TextBodySmall>
          )
        } else
          return (
            <TextBody>
              <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
            </TextBody>
          )
      },

      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading1>
          <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
        </Heading1>
      ),

      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2>
          <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
        </Heading2>
      ),

      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3>
          <AnimateSlideIn delay={delay}>{children}</AnimateSlideIn>
        </Heading3>
      ),

      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),

      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),

      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <InViewSection>
            <ListItem delay={delay}>{children}</ListItem>
          </InViewSection>
        )
      },

      [BLOCKS.HR]: () => <hr />,

      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { description, gatsbyImageData } = node.data.target

        return <GatsbyImage image={gatsbyImageData} alt={description} />
      },

      /*
       ** Inlines
       */

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),

      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <a href={node.data.target.file.url}>{children}</a>
      ),

      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <PageTransitionLink to={`/${node.data.target.slug}`}>
          {children}
        </PageTransitionLink>
      ),
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return <RichTextMain>{renderRichText(content, options)}</RichTextMain>
}

RichTextSlide.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
}

export default RichTextSlide
