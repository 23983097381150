import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import borderBackground from './images/acquisition-background.jpg'

export const AcquisitionBlockMain = styled.div`
  ${clamp('padding-top', 70, 250)}
  ${clamp('padding-bottom', 44, 230)}
  color: ${colors.cream};
  text-align: center;
  background-color: ${colors.darkBrown};

  ${mq.tabletL} {
    text-align: left;
  }
`

export const AcquisitionBlockBorder = styled.div`
  ${clamp('height', 60, 100)}
  background-image: url(${borderBackground});
  background-size: cover;
  background-position: center;
`

export const AcquisitionBlockDescription = styled.div`
  ${mq.tabletLMax} {
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4.4rem;
  }
`

export const AcquisitionBlockText = styled.div`
  max-width: 32rem;
  padding-bottom: 2.2rem;

  ${mq.tabletLMax} {
    margin-left: auto;
    margin-right: auto;
  }

  > * + * {
    margin-top: 2rem;
  }
`
