import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'

export const Hero = styled.div`
  position: relative;
`
export const HeadingContainer = styled.div`
  padding-right: 4rem;
  position: relative;
  ${clamp('max-width', 580, 700)}

  ${mq.tabletP} {
    padding-right: 0;
  }

  svg {
    ${clamp('height', 97, 147)}
    ${clamp('width', 97, 147)}
    position: absolute;
    right: 0;

    ${clamp('bottom', -10, -30)}

    ${({ inView }) => {
      return inView === true
        ? `
        #border {
            opacity:1;
          stroke-dashoffset: 0;
        }
        path {
          &:not(#border) {
            opacity:1;
          }
        }
        `
        : `
        #border {
          opacity:0;
          stroke-dashoffset: 55rem;
        }
        path {
          &:not(#border) {
            opacity:0;
          }
        }
        `
    }}

    ${mq.desk} {
      transform: translateX(100%);
    }

    #border {
      stroke-dasharray: 55rem;
      transition: stroke-dashoffset 1.5s ease 1.5s, opacity 1s ease 1.5s;
    }

    path {
      &:not(#border) {
        transition: opacity 1s ease 1.5s;
      }
    }
  }
`
